import { Grid, Typography } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import ContactForm from "../components/ContactForm/ContactForm"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

const StyledSection = styled.section`
  padding: 150px 0;
`

const StyledImage = styled(Image)`
  position: absolute !important;
  top: 2vw;
  left: 0;
  width: ${({ medium }) => (medium ? "80%" : "50%")};
  transform: ${({ medium }) =>
    medium ? "translate(-50%, -20%)" : "translate(-50%, -50%)"};

  max-width: ${({ medium }) => (medium ? "674px" : "428px")};
`

const StyledContactImage = styled(Image)`
  height: 100%;
`

const StyledTypography = styled(Typography)`
  font-size: 2.5rem;
  @media only screen and (min-width: 1024px) {
    font-size: 3rem;
  }
`

const StyledGrid = styled(Grid)`
  position: relative;
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
`

const Contact = () => {
  const { formatMessage } = useIntl()
  const data = useStaticQuery(graphql`
    {
      cloud: file(name: { eq: "cloud-small" }) {
        childImageSharp {
          fluid(maxWidth: 428, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      cloudMedium: file(name: { eq: "cloud-medium" }) {
        childImageSharp {
          fluid(maxWidth: 674, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      contactPhoto: file(name: { eq: "contact-photo" }) {
        childImageSharp {
          fluid(maxWidth: 1900, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <Grid container justify="space-between">
        <Grid
          item
          xs={12}
          md={7}
          style={{ padding: "0 25px", zIndex: 10, margin: "0 auto" }}
        >
          <StyledTypography
            variant="h4"
            style={{ textAlign: "left", margin: "20px 0 20px" }}
          >
            <FormattedMessage
              id="Contact title"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                br: <br />,
                b: chunks => <b>{chunks}</b>,
              }}
            />
            <span role="img" aria-label="balloon">
              &#x1F388;
            </span>
          </StyledTypography>
          <Typography
            variant="h6"
            component="p"
            style={{
              textAlign: "left",

              margin: "50px 0",
            }}
          >
            {formatMessage({ id: "We are also available at" })}
            <a href="tel:+48697303011" style={{ whiteSpace: "nowrap" }}>
              +48 697-303-011
            </a>
          </Typography>
          <Typography
            variant="h6"
            component="p"
            style={{ display: "flex", alignItems: "center" }}
          >
            {formatMessage({
              id: "Do you have any questions? Write us a message!",
            })}{" "}
            <ArrowDownwardIcon />
          </Typography>
          <ContactForm />
        </Grid>
        <StyledGrid item xs={12} md={5}>
          <StyledImage fluid={data.cloudMedium.childImageSharp.fluid} medium />
          <StyledImage fluid={data.cloud.childImageSharp.fluid} />
          <StyledContactImage fluid={data.contactPhoto.childImageSharp.fluid} />
        </StyledGrid>
      </Grid>
    </StyledSection>
  )
}

export default Contact
