import React from "react"
import { Helmet } from "react-helmet"
import Navigation from "../components/Navigation/Navigation"
import Contact from "../views/Contact"
import Footer from "../views/Footer"
import { useIntl } from "gatsby-plugin-intl"

const ContactUs = () => {
  const { formatMessage, locale } = useIntl()
  const contact = formatMessage({ id: "Contact" })

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        <meta charSet="utf-8" />
        <title data-react-helmet="true">
          {contact} | Skywalkers Balloon Club
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
        />
        <meta name="description" data-react-helmet="true" content="" />
        <meta
          data-react-helmet="true"
          property="og:title"
          content={`${contact} | Skywalkers Balloon Club`}
        />
        <meta data-react-helmet="true" property="og:description" content="" />
        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/homepage.jpg?alt=media&token=bde9c4f3-3638-4f5a-8e32-6b10a1bcd834"
        />
      </Helmet>
      <Navigation />

      <Contact />

      <Footer />
    </>
  )
}

export default ContactUs
