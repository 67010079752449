import React, { useRef, useState } from "react"
import styled from "styled-components"
import emailjs from "emailjs-com"
import CircularProgress from "@material-ui/core/CircularProgress"
import ContactFormDialog from "../ContactFormDialog/ContactFormDialog"

import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  TextField,
} from "@material-ui/core"
import { useIntl } from "gatsby-plugin-intl"

const StyledGrid = styled(Grid)`
  .MuiOutlinedInput-notchedOutline {
    border-color: rgb(0 0 0 / 100%) !important;
  }

  .MuiInputLabel-animated {
    color: #8c8c8c;
    font-size: 14px;
  }
  input,
  select,
  textarea {
    color: #000 !important;
  }

  input::selection {
    background: #f7a494;
  }
  input#name:-webkit-autofill {
    background-color: #0c2a3a !important;
  }
`

const ContactForm = () => {
  const [nameValue, setNameValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [messageValue, setMessageValue] = useState("")
  const [isContactFormDialogOpen, setContactFormOpen] = useState(false)
  const [error, setError] = useState(false)
  const { formatMessage } = useIntl()

  const circularRef = useRef()
  const buttonTxtRef = useRef()

  const handleSubmit = e => {
    e.preventDefault()

    circularRef.current.style.opacity = 1
    buttonTxtRef.current.style.opacity = 0

    emailjs
      .sendForm(
        process.env.GATSBY_EMAIL_JS_SERVICE,
        process.env.GATSBY_EMAIL_JS_TEMPLATE_MESSAGE,
        e.target,
        process.env.GATSBY_EMAIL_JS_USER
      )
      .then(
        result => {
          circularRef.current.style.opacity = 0
          buttonTxtRef.current.style.opacity = 1
          setNameValue("")
          setEmailValue("")
          setMessageValue("")
          setContactFormOpen(true)
          setError(false)
          setTimeout(() => {
            setContactFormOpen(false)
          }, 3000)
        },
        error => {
          circularRef.current.style.opacity = 0
          buttonTxtRef.current.style.opacity = 1
          setNameValue("")
          setEmailValue("")
          setMessageValue("")
          setContactFormOpen(true)
          setError(true)
          setTimeout(() => {
            setContactFormOpen(false)
          }, 3000)
        }
      )
  }

  const handleNameChange = e => {
    setNameValue(e.target.value)
  }
  const handleEmailChange = e => {
    setEmailValue(e.target.value)
  }
  const handleMessageChange = e => {
    setMessageValue(e.target.value)
  }
  return (
    <form onSubmit={handleSubmit}>
      <StyledGrid>
        <Grid container direction="column">
          <FormControl variant="outlined" style={{ margin: "20px 0 10px" }}>
            <InputLabel
              htmlFor="name"
              style={{ color: "#8c8c8c", fontSize: "14px" }}
            >
              {formatMessage({ id: "Name and Surname" })}
            </InputLabel>
            <OutlinedInput
              id="name"
              label={formatMessage({ id: "Name and Surname" })}
              variant="outlined"
              style={{ width: "80vw", maxWidth: "500px" }}
              required={true}
              value={nameValue}
              onChange={handleNameChange}
              type="text"
              name="name"
            />
          </FormControl>
          <FormControl variant="outlined" style={{ margin: "10px 0 20px" }}>
            <InputLabel
              htmlFor="mail"
              style={{ color: "#8c8c8c", fontSize: "14px" }}
            >
              Email
            </InputLabel>
            <OutlinedInput
              id="mail"
              label="Email"
              variant="outlined"
              style={{ width: "80vw", maxWidth: "500px" }}
              required={true}
              value={emailValue}
              onChange={handleEmailChange}
              type="email"
              name="email"
            />
          </FormControl>
          <TextField
            id="outlined-multiline-static"
            label={formatMessage({ id: "Message" })}
            multiline
            style={{ width: "80vw", maxWidth: "500px" }}
            rows={6}
            variant="outlined"
            value={messageValue}
            onChange={handleMessageChange}
            name="message"
            required={true}
          />

          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{
              marginTop: "20px",
              maxWidth: "100px",
              backgroundColor: `#0194da`,
            }}
          >
            <CircularProgress
              style={{
                height: "24px",
                width: "24px",
                position: "absolute",
                opacity: 0,
              }}
              ref={circularRef}
            />
            <span ref={buttonTxtRef} style={{ color: "white" }}>
              {formatMessage({ id: "Send" })}
            </span>
          </Button>
        </Grid>
      </StyledGrid>
      <ContactFormDialog
        isContactFormDialogOpen={isContactFormDialogOpen}
        error={error}
      />
    </form>
  )
}

export default ContactForm
