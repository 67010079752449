import React from "react"
import styled from "styled-components"
import { Dialog, Divider, Typography } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgb(2 12 27 / 90%);
  }
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.darkBlue};

    .MuiPaper-root {
      padding: 10px 0 40px;
      background: linear-gradient(
          189deg,
          rgb(27 114 177) 0%,
          rgb(9 113 144) 26%,
          rgb(34 103 136) 27%,
          rgb(5 37 70) 100%
        ),
        radial-gradient(
          circle,
          rgba(88, 202, 245, 1) 0%,
          rgba(12, 117, 167, 1) 100%,
          rgba(0, 100, 148, 1) 100%,
          rgba(4, 13, 55, 1) 100%
        );
      color: white;
      width: 100%;
    }
  }

  .MuiDialog-paper {
    margin: 0;
    padding: 20px;
    width: 100%;
  }
`

const StyledImage = styled(Image)`
  width: 100px;
  margin: 0 auto 30px;
`
const StyledTypography = styled(Typography)`
  width: 90%;
  margin: 20px auto 0;
  font-weight: 300;
`

const AfterSendEmailDialog = props => {
  const { isContactFormDialogOpen, error } = props
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo-white" }) {
        childImageSharp {
          fixed(width: 150, quality: 90) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledDialog
      aria-labelledby="simple-dialog-title"
      open={isContactFormDialogOpen}
    >
      <StyledImage fixed={data.file.childImageSharp.fixed} />

      <Divider style={{ backgroundColor: "rgb(0 0 0 / 0.1)" }} />
      <StyledTypography variant="subtitle2" component="p" error={error}>
        {error ? (
          <>
            Coś poszło nie tak... <br />
            Prosimy spróbować ponownie
          </>
        ) : (
          <>Mail został wysłany</>
        )}
        <br />
        <br />
        Dziękujemy!
        <br />
        Zespół Skywalkers Balloon Club
      </StyledTypography>
    </StyledDialog>
  )
}

export default AfterSendEmailDialog
